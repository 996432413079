import { Cascader, DatePicker, Input, InputNumber, Modal, Select, Space, Table, TableProps } from 'antd';
import { ExpandableConfig, TableLocale, TablePaginationConfig, TableRowSelection } from 'antd/lib/table/interface';
import moment from 'moment';
import React from 'react';

import { colors } from '../../helpers/allStyles';
import utils from '../../helpers/utils';
import { TableColumn } from '@/legacy-types'
import { hoverStyle } from '../settings/nativeApp/tabs/StylingTab';
import Break from '../Break';
import HoverQuestion from '../HoverQuestion';
import GButton from './GButton';
import GTooltip from './GTooltip';

// Comparison types
export const COMPARISON_TYPES: { [key: string]: string[] } = {
	string: ['=', '!=', 'starts with', 'ends with', 'contains', 'not contains', 'is empty', 'is not empty'],
	number: ['=', '!=', '>', '<', '>=', '<=', 'between'],
	date: ['=', '!=', '>', '<', '>=', '<=', 'is empty', 'is not empty', 'between'],
	boolean: ['=', '!=', 'is empty', 'is not empty'],
};

const allComparisonTypesMap: { [key: string]: string } = {
	'=': 'equals',
	'!=': 'does not equal',
	'>': 'is greater than',
	'<': 'is less than',
	'>=': 'is greater than or equal to',
	'<=': 'is less than or equal to',
	'starts with': 'starts with',
	'ends with': 'ends with',
	'contains': 'contains',
	'not contains': 'does not contain',
	'is empty': 'is empty',
	'is not empty': 'is not empty',
	'between': 'is between',
};

const allComparisonTypesToOptions: { label: string; value: string }[] = Object.keys(allComparisonTypesMap).map((key: string) => ({
	label: allComparisonTypesMap[key],
	value: key,
}));
const comparisonOptionsByType: { [key: string]: { label: string; value: string }[] } = Object.keys(COMPARISON_TYPES).reduce((result: any, key: string) => {
	result[key] = COMPARISON_TYPES[key].map((x: string) => ({ label: allComparisonTypesMap[x], value: x }));
	return result;
}, {});

export interface GlobalTableProps<T = any> {
	columns: TableColumn<T>[];
	dataSource: (T | any)[];

	bottomRadius?: boolean;
	noRadius?: boolean;
	alternateHeader?: boolean;
	className?: string;
	noTableBorders?: boolean;
	scroll?: { x?: number | string; y?: number | string };
	alternateRows?: boolean;
	props?: TableProps<T | any>;
	locale?: TableLocale;
	noExpandPadding?: boolean;
	pagination?: TablePaginationConfig | false;
	wrapperStyle?: React.CSSProperties;
	options?: {
		noHeaderBG?: boolean;
		bypassBorders?: boolean;
	};

	dataFilters?: GDataFilter[];
	allowMultiSort?: boolean;

	loading?: boolean;

	totalsColumns?: React.ReactNode[];
	rowSelection?: TableRowSelection<T | any> | undefined;
	expandable?: ExpandableConfig<T | any> | undefined;
}

type GFilterType = 'string' | 'number' | 'date' | 'boolean' | 'array' | 'object';
export interface GDataFilter {
	title: string;
	tooltip?: string;
	property: string; // path to property IE 'user.name'
	type: GFilterType;
	allowedComparisons?: string[];
	test?: (value: any) => boolean;
}

interface ActiveDataFilter extends GDataFilter {
	uuid: string;
	operator?: 'AND' | 'OR';
	comparison?: string;
	value?: any;
}

export default function GTable<T = any>({ columns, dataSource, ...props }: GlobalTableProps<T>) {
	const tableRef: any = React.useRef(null);

	const [open, setOpen] = React.useState<boolean>(false);
	const [filters, setFilters] = React.useState<ActiveDataFilter[]>([]);

	const classes: string[] = [];
	if (props.noTableBorders) classes.push('noTable');
	if (props.options?.noHeaderBG || props.noTableBorders) classes.push('no-header-bg');
	if (props.options?.bypassBorders) classes.push('bypass');
	// if (props.alternateHeader) classes.push('alternate-header')
	// if (props.noRadius) classes.push('no-radius')
	if (props.bottomRadius) classes.push('bottom-radius');

	// List of all available data filters
	const dataFilters: GDataFilter[] = React.useMemo(() => {
		const combined: GDataFilter[] = props.dataFilters || [];
		// Data filters can also be applied via columns, so we need to add those to the list
		(columns || []).filter((col: TableColumn) => col.dataFilter).forEach((col: any) => combined.push(col.dataFilter));
		return combined;
	}, [props.dataFilters, columns]);

	const hasTotalsColumn = !!props.totalsColumns?.length;
	const totalsColumn = { key: 'totals-column' };

	const hasDataFilters = dataFilters.length > 0,
		hasActiveFilters = filters.length > 0;

	// List of all table columns
	const tableColumns: TableColumn[] = React.useMemo(() => {
		return Array.from([...columns])
			.filter((col: TableColumn) => col.shown === undefined || col.shown === true)
			.map((col: TableColumn, i) => {
				// If the column title is a string, we apply some default styling to it
				if (typeof col.title === 'string')
					col.title = (
						<span
							style={{
								// color: props.alternateHeader ? '#B5B5BD' : colors.secondary.text(),
								color: colors.secondary.text(),
								fontWeight: 600,
								fontSize: '.75rem',
								...col.titleStyle,
							}}
						>
							{col.title}
						</span>
					);

				const sortKey = typeof col.sort === 'string' ? col.sort : col.key;
				// If the columns has .sort, we apply some default sorting to it
				if (!!col.sort && sortKey) {
					col.sorter = {
						compare: (a: any, b: any) => {
							if (a.key === 'totals-column') return 0;
							if (b.key === 'totals-column') return 0;
							const aVal: any = getPropertyByPath(a, sortKey || '');
							const bVal: any = getPropertyByPath(b, sortKey || '');
							if (bVal === undefined || aVal === undefined) return 0;
							// If the value is a string, we use localeCompare
							if (typeof aVal === 'string') return aVal.localeCompare(bVal || '');
							// If the value is a number, we use a simple subtraction
							if (typeof aVal === 'number') return aVal - bVal || 0;
							// If the value is a boolean, we use a simple subtraction
							if (typeof aVal === 'boolean') return aVal === bVal ? 0 : aVal ? 1 : -1;
							// If the value is a date, we use a simple subtraction
							if (aVal instanceof Date) return aVal.getTime() - bVal.getTime() || 0;
							// If the value is an array, we sort array lengths
							if (Array.isArray(aVal)) return aVal.length - (bVal || []).length || 0;
							// If the value is an object, we sort object lengths
							if (typeof aVal === 'object') return Object.keys(aVal).length - Object.keys(bVal || {}).length || 0;
							// If the value is anything else, we return 0
							return 0;
						},
						multiple: props.allowMultiSort ? columns.length - i : undefined,
					};
				}

				// If the column has a built in sorter, ensure totals rows are always at the top
				if (!!col.sorter && hasTotalsColumn) {
					const isCompare = typeof col.sorter === 'function';
					if (isCompare) {
						const newCB = (col.sorter as Function).bind({});
						col.sorter = (a: any, b: any) => {
							if (a.key === 'totals-column') return 0;
							if (b.key === 'totals-column') return 0;
							return newCB(a, b);
						};
					}
				}

				// If they have totals columns we need to update the render function
				if (hasTotalsColumn) {
					const newRender = col.render ? col.render.bind({}) : () => null;
					col.render = (value: any, record: any, index: number) => {
						if (record.key === 'totals-column') {
							return <span style={{ fontWeight: 600, fontSize: '.75rem' }}>{(props.totalsColumns || [])[i]}</span>;
						}
						return newRender(value, record, index);
					};
				}

				return col;
			});
	}, [columns, dataSource]);

	// List of all active data filters
	const tableData: any[] = React.useMemo(() => {
		// If there are no data filters, return the data source
		if (!hasActiveFilters) return [...(dataSource || [])];
		return [...(dataSource || [])].filter((curr: any) => {
			// If there are no active filters, return true
			if (!filters || filters.length === 0) return true;

			// If there are filters, check if the current item passes
			return filters.reduce((result: boolean, filter: ActiveDataFilter) => {
				let value: any = getPropertyByPath(curr, filter.property);

				// If the filter has a test, use that
				if (filter.test) {
					if (filter.operator === 'OR') {
						return result || filter.test(value);
					} else {
						return result && filter.test(value);
					}
				}

				// Parse the value if it's a date && it's a number then it's unix time
				if (filter.type === 'date' && typeof value === 'number') value = new Date(value * 1000);
				// If it's a date, and not an instance of date, try to parse a date
				if (filter.type === 'date' && !(value instanceof Date)) value = new Date(value);
				// If it's an invalid date set it to undefined
				if (filter.type === 'date' && isNaN(value) && isNaN(value?.getTime())) value = undefined;

				// If filter type is an object, we make value the length of the object keys
				if (filter.type === 'object') value = Object.keys(value || {}).length;
				// If filter type is an array, we make value the length of the array
				if (filter.type === 'array') value = (value || []).length;

				// If it is type number, and value is undefined, set value to 0
				if (filter.type === 'number' && value === undefined) value = 0;

				// If the filter has a comparison, use that
				if (filter.comparison) {
					let filterResult: boolean;
					switch (filter.comparison) {
						case '=':
							filterResult = value == filter.value;
							break;
						case '!=':
							filterResult = value != filter.value;
							break;
						case '>':
							filterResult = value > filter.value;
							break;
						case '<':
							filterResult = value < filter.value;
							break;
						case '>=':
							filterResult = value >= filter.value;
							break;
						case '<=':
							filterResult = value <= filter.value;
							break;
						case 'starts with':
							filterResult = value.startsWith(filter.value);
							break;
						case 'ends with':
							filterResult = value.endsWith(filter.value);
							break;
						case 'contains':
							filterResult = value.includes(filter.value);
							break;
						case 'not contains':
							filterResult = !value.includes(filter.value);
							break;
						case 'is empty':
							filterResult = value === undefined || value === null || value === '';
							break;
						case 'is not empty':
							filterResult = value !== undefined && value !== null && value !== '';
							break;
						case 'between':
							filterResult = value >= filter.value[0] && value <= filter.value[1];
							break;
						default:
							filterResult = false;
					}

					if (filter.operator === 'OR') {
						return result || filterResult;
					} else {
						return result && filterResult;
					}
				}

				// If the filter has no comparison, return false
				return false;
			}, true);
		});
	}, [dataSource, filters]);

	const noHead = false && (props.noRadius || props.bottomRadius);

	const borderRadius = {
		topLeft: noHead ? 0 : 8,
		topRight: noHead ? 0 : 8,
		bottomLeft: 8, //(props.noRadius && !props.bottomRadius) ? 0 : 8,
		bottomRight: 8, //(props.noRadius && !props.bottomRadius) ? 0 : 8,
	};

	return (
		<>
			<DataFilterModal
				{...{
					dataFilters,
					open,
					setOpen,
					defaultFilters: filters,
					onApply: (filters: ActiveDataFilter[]) => setFilters(filters),
				}}
			/>
			<div className={`gtable ${props?.className || ''} ${classes.join(' ')}`}>
				<div
					className={`wrapper ${utils.darkMode ? 'dark' : ''} ${props.noExpandPadding ? 'no-expand-padding' : ''}`}
					style={{
						border: props.noTableBorders ? '' : `1px solid ${!utils.darkMode ? '#E6E8F0' : 'rgb(48, 48, 48)'}`,
						borderRadius: `${borderRadius.topLeft}px ${borderRadius.topRight}px ${borderRadius.bottomRight}px ${borderRadius.bottomLeft}px`,
						...props.wrapperStyle,
					}}
				>
					<div />
					<Table
						ref={tableRef}
						size="small"
						loading={props.loading}
						scroll={{ x: 'max-content', ...props.scroll }}
						columns={tableColumns.map((col: TableColumn, i) => {
							// If there's a dataFilter && this is the first column, we add a filter icon to the title
							if (hasDataFilters && i === 0) {
								const title = (
									<Space>
										<GTooltip tooltip={!hasActiveFilters ? 'Click to use advanced data filters' : 'Click to edit advanced data filters'}>
											<i
												className={`${hasActiveFilters ? 'fa-regular fa-filter' : 'fa-regular fa-filter'} linked`}
												onClick={() => setOpen(true)}
												style={{
													fontSize: '.75rem',
													color: hasActiveFilters ? '#1890ff' : '#7b7b80',
													textShadow: hasActiveFilters ? '2px 2px 1px rgba(150,205,255,0.5)' : undefined,
												}}
											/>
										</GTooltip>
										{col.title}
									</Space>
								);
								return { ...col, title };
							}
							return col as any;
						})}
						// columns={[
						//     {
						//         sorter
						//     }
						// ]}

						dataSource={[...(hasTotalsColumn ? [totalsColumn] : []), ...tableData]}
						locale={{
							// filterConfirm: React.ReactNode;
							// filterReset: React.ReactNode;
							// filterEmptyText: React.ReactNode;
							// filterCheckall: React.ReactNode;
							// emptyText: React.ReactNode | (() => React.ReactNode);
							// selectAll: React.ReactNode;
							// selectNone: React.ReactNode;
							// selectInvert: React.ReactNode;
							// selectionAll: React.ReactNode;
							// filterTitle: '',
							// filterSearchPlaceholder: '',
							// sortTitle: '',
							// expand: '',
							// collapse: '',
							triggerDesc: 'Click to sort the items in descending order',
							triggerAsc: 'Click to sort the items in ascending order',
							cancelSort: 'Click to stop sorting',
							...props.locale,
						}}
						// sortIcon
						onRow={(record, i: any) => ({
							className: i === 0 && !!Object.keys(props.totalsColumns || {})?.length ? 'firstRow' : '',
							// style: props.alternateRows ? { background: i % 2 == 1 ? utils.darkMode ? '#F9F9F905' : 'rgba(249,249,249,0.7)' : '' } : {}
						})}
						// @ts-ignore
						pagination={{
							showSizeChanger: true,
							defaultPageSize: 50,
							pageSizeOptions: [10, 20, 50, 100, 500, 1000, 2000],
							position: ['bottomCenter'],
							hideOnSinglePage: true,
							...props.pagination,
						}}
						rowSelection={props.rowSelection}
						expandable={props.expandable}
						{...props.props}
					/>
				</div>
			</div>
		</>
	);
}

function DataFilterModal(props: {
	dataFilters: GDataFilter[];
	open: boolean;
	setOpen: (visible: boolean) => void;

	defaultFilters?: ActiveDataFilter[];
	onApply: (filters: ActiveDataFilter[]) => void;
}) {
	const [saveAnyways, setSaveAnyways] = React.useState<boolean>(false);
	const [filters, setFilters] = React.useState<ActiveDataFilter[]>(props?.defaultFilters || []);

	React.useEffect(() => {
		setFilters(props?.defaultFilters || []);
	}, [props.open]);

	const addFilter = (filter: GDataFilter) => setFilters((filters) => [...filters, { ...filter, uuid: utils.getUUID() }]);
	const DefaultTitle = ({ filter }: { filter: GDataFilter }): any => {
		if (filter.title) return filter.title;
		let type: string = filter.type;
		if (filter.type === 'string') type = 'Text';
		if (filter.type === 'number') type = 'Number';
		if (filter.type === 'date') type = 'Date';
		if (filter.type === 'boolean') type = 'True/False';
		if (filter.type === 'array') type = 'List';
		if (filter.type === 'object') type = 'Object';
		const name = utils.capitalize((filter.property || '').split('.').pop());
		return (
			<>
				Property: {name} ({type}){' '}
			</>
		);
	};

	const { allFilterOptions, allPropertiesToTypesMap } = React.useMemo(() => {
		const allFilterOptions: { label: React.ReactNode; value: string }[] = props.dataFilters.map((filter: GDataFilter) => ({
			label: filter.title || <DefaultTitle {...{ filter }} />,
			value: filter.property,
		}));
		const allPropertiesToTypesMap: { [key: string]: string } = props.dataFilters.reduce(
			(result: any, filter: GDataFilter) => ({ ...result, [filter.property]: filter.type }),
			{},
		);
		return { allFilterOptions, allPropertiesToTypesMap };
	}, [props.dataFilters]);

	const filterComponents = React.useMemo(() => {
		return filters.map((filter: ActiveDataFilter, i) => {
			const updateFilter = (x: Partial<ActiveDataFilter>) =>
				setFilters((filters) => filters.map((t: ActiveDataFilter) => (t.uuid === filter.uuid ? { ...t, ...x } : t)));
			const deleteFilter = () => setFilters((filters) => filters.filter((t: ActiveDataFilter) => t.uuid !== filter.uuid));
			// Return the filter component
			return (
				<React.Fragment key={filter.uuid}>
					<DataFilterItem
						index={i}
						total={filters.length - 1}
						{...{ filter, updateFilter, deleteFilter, allFilterOptions, allPropertiesToTypesMap }}
					/>
				</React.Fragment>
			);
		});
	}, [filters, allFilterOptions, allPropertiesToTypesMap]);

	const hasFilters = filters.length > 0,
		hasMultipleFilters = filters.length > 1;
	const description = `Utilize this feature to sort and filter through the table's dataset. This allows you to display data rows that precisely align with your specified comparison criteria based on the data's properties.`;

	// Is the last filter finished?
	const lastFilter = filters[filters.length - 1],
		lastFilterFinished = !!lastFilter?.property && !!lastFilter?.comparison && lastFilter?.value !== undefined;

	return (
		<>
			<Modal
				footer={
					<>
						<div>
							<Space>
								<GButton
									size="large"
									onClick={() => props.setOpen(false)}
								>
									Cancel
								</GButton>
								{
									<>
										{!!props.defaultFilters?.length && (
											<>
												<GButton
													size="large"
													popover={{
														popConfirm: true,
														tooltip: { tooltip: 'Clear all filters' },
														content: 'Are you sure you want to clear all filters?',
														onConfirm: () => {
															props.onApply([]);
															props.setOpen(false);
														},
													}}
												>
													Clear Filters
												</GButton>
											</>
										)}
									</>
								}
								<GButton
									size="large"
									type="primary"
									popover={{
										popConfirm: true,
										tooltip: { tooltip: 'Save data filters' },
										content: 'Are you sure you want to apply these data filters?',
										onConfirm: () => {
											// Filter out all unfinished filters
											const filtered = filters?.filter((filter: ActiveDataFilter) => filter.property && filter.comparison && filter.value !== undefined) || [];
											const incompleteCount = filters.length - filtered.length;

											if (!saveAnyways && !!incompleteCount) {
												utils.showErr(
													`You have ${incompleteCount} unfinished filter${
														incompleteCount === 1 ? '' : 's'
													}. Click "Save Anyways" to save the filters you have finished.`,
												);
												return setSaveAnyways(true);
											}

											props.onApply(filtered);
											props.setOpen(false);
											setSaveAnyways(false);
										},
									}}
								>
									{saveAnyways ? 'Save Anyways' : 'Apply Data Filters'}
								</GButton>
							</Space>
						</div>
					</>
				}
				open={props.open}
				bodyStyle={{ paddingTop: 16 }}
			>
				<span style={{ fontWeight: 600, fontSize: '1rem' }}>Advanced Data Filters</span>
				<Break
					air
					v={hasFilters ? 5 : 20}
				/>

				{hasFilters && (
					<>
						<div style={{ color: utils.darkMode ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)' }}>
							<span style={{}}>{description}</span>
						</div>

						<Break
							air
							v="10"
						/>

						<div
							style={{
								display: 'grid',
								gridTemplateColumns: 'repeat(1, 1fr)',
								columnGap: 8,
								rowGap: 8,
								overflowX: 'hidden',
								overflowY: 'auto',
								scrollbarWidth: 'thin',
								maxHeight: '50vh',
							}}
						>
							{filterComponents}
						</div>

						{lastFilterFinished && (
							<>
								<Break
									air
									v="10"
								/>

								<GButton>
									<span
										style={{ color: '#1890ff' }}
										onClick={() => addFilter({ uuid: utils.getUUID(), operator: 'AND' } as ActiveDataFilter)}
									>
										Add another filter
									</span>
								</GButton>
							</>
						)}
					</>
				)}

				{!hasFilters && (
					<>
						<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: 150 }}>
							<div style={{ padding: 5 }}>
								<i
									className="fa-regular fa-filter-circle-xmark"
									style={{ fontSize: '2rem', color: utils.darkMode ? 'rgba(255,255,255,0.35)' : 'rgba(0,0,0,0.35)' }}
								/>
							</div>
							<div style={{ textAlign: 'center' }}>
								<span style={{ fontSize: '.875rem', color: utils.darkMode ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)' }}>{description}</span>
								<Break air />
								<span
									className="linked"
									onClick={() => addFilter({ uuid: utils.getUUID(), operator: 'AND' } as ActiveDataFilter)}
								>
									Click to configure
								</span>
							</div>
						</div>
					</>
				)}
			</Modal>
		</>
	);
}

function DataFilterItem({
	filter,
	...props
}: {
	index: number;
	total: number;
	filter: ActiveDataFilter;
	updateFilter: (x: Partial<ActiveDataFilter>) => void;
	deleteFilter: () => void;

	allPropertiesToTypesMap: { [key: string]: string };
	allFilterOptions: { label: React.ReactNode; value: string }[];
}) {
	const isNotFirstIndex = props.index !== 0;
	const hasType = !!filter.type;
	const hasSelectedProperty = !!filter.property;
	const hasSelectedComparison = !!filter.comparison;
	const hasSelectedValue = filter.value !== undefined;

	const isFinishedFilter = hasSelectedProperty && hasSelectedComparison && hasSelectedValue;
	const compareOptions = hasType ? comparisonOptionsByType[filter.type] : allComparisonTypesToOptions;

	let tooltip = 'The data must match this filter requirement';
	if (isNotFirstIndex) {
		if (filter.operator === 'AND') tooltip = `The data must match this filter requirement AND the previous filter requirement's`;
		else tooltip = `The data must match this filter requirement OR the previous filter requirement's`;
	}

	return (
		<>
			<div style={{ display: 'flex', columnGap: 8, alignItems: 'center' }}>
				<Space>
					{!isNotFirstIndex && <span style={{ fontWeight: 500 }}>Show me data where...</span>}
					{isNotFirstIndex && (
						<>
							<GTooltip tooltip={`Click to change the operator to ${filter.operator === 'AND' ? 'OR' : 'AND'}`}>
								<i
									className="fa-regular fa-repeat linked"
									style={{ fontSize: '.875rem' }}
									onClick={() => props.updateFilter({ operator: filter.operator === 'AND' ? 'OR' : 'AND' })}
								/>
							</GTooltip>
							<span style={{ fontWeight: 500 }}>{filter.operator} where...</span>
						</>
					)}
					<HoverQuestion
						style={hoverStyle}
						title={tooltip}
					/>
				</Space>
				<div style={{ flex: 1, borderTop: isNotFirstIndex ? `1px solid ${colors.primary.dividerColor()}` : '' }} />
				<GButton
					type="ghost"
					style={{ color: colors.text.lighterer() }}
					icon={
						<i
							className="fa-regular fa-trash-can errorHover"
							style={{}}
						/>
					}
					popover={{
						popConfirm: true,
						tooltip: { tooltip: 'Remove this filter requirement' },
						content: 'Are you sure you want to remove this filter?',
						onConfirm: props.deleteFilter,
					}}
				/>
			</div>
			<div style={{ display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)', columnGap: 8, rowGap: 8 }}>
				<div style={{ gridColumn: 'span 1' }}>
					<Cascader
						value={filter.property ? [filter.property] : []}
						onChange={(options) => {
							const property: string = ((options || [])[0] || '') as string;
							const type: GFilterType | undefined = (property ? props.allPropertiesToTypesMap[property] : undefined) as any;
							props.updateFilter({ property, type });
						}}
						placeholder="Select a property to filter by"
						options={props.allFilterOptions}
						style={{ minWidth: '100%' }}
						showSearch={{
							filter: (inputValue: any, path: any) => (path || []).some((option: any) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1),
						}}
					/>
				</div>

				<div style={{ gridColumn: 'span 1', display: 'flex', columnGap: 8, alignItems: 'center' }}>
					{hasSelectedProperty && (
						<>
							<Select
								value={filter.comparison}
								placeholder="Select comparison type"
								style={{ minWidth: 120 }}
								onChange={(x) => props.updateFilter({ comparison: x as string | undefined })}
								options={compareOptions}
							/>
							{/* {(contents.map((x: any, i) => <div key={i}>{x}</div>))} */}
						</>
					)}

					{hasSelectedComparison && (
						<>
							{filter.type === 'boolean' && (
								<>
									<Select
										value={filter.value}
										placeholder="True or False"
										style={{ minWidth: 120 }}
										onChange={(value) => props.updateFilter({ value })}
										options={[
											{ label: 'True', value: true },
											{ label: 'False', value: false },
										]}
									/>
								</>
							)}

							{filter.type === 'string' && (
								<>
									<Input
										value={filter.value}
										placeholder="Enter a value"
										onChange={({ target }) => props.updateFilter({ value: target.value })}
										style={{ minWidth: 120 }}
									/>
								</>
							)}

							{filter.type === 'number' && (
								<>
									{filter.comparison === 'between' && (
										<>
											<InputNumber
												value={filter.value ? filter.value[0] : undefined}
												placeholder="Enter a value"
												onChange={(value) => props.updateFilter({ value: [value, filter.value ? filter.value[1] : undefined] })}
												style={{ minWidth: 120, borderRadius: 6 }}
											/>

											<span style={{}}>and</span>

											<InputNumber
												value={filter.value ? filter.value[1] : undefined}
												placeholder="Enter a value"
												onChange={(value) => props.updateFilter({ value: [filter.value ? filter.value[0] : undefined, value] })}
												style={{ minWidth: 120, borderRadius: 6 }}
											/>
										</>
									)}

									{filter.comparison !== 'between' && (
										<>
											<InputNumber
												value={filter.value}
												placeholder="Enter a value"
												onChange={(value) => props.updateFilter({ value })}
												style={{ minWidth: 120, borderRadius: 6 }}
											/>
										</>
									)}
								</>
							)}

							{filter.type === 'date' && (
								<>
									{filter.comparison === 'between' && (
										<>
											<DatePicker.RangePicker
												allowClear
												value={filter.value ? [moment(filter.value[0]), moment(filter.value[1])] : undefined}
												onChange={(value) => props.updateFilter({ value: value?.map((x: any) => x.toDate()) })}
												style={{ minWidth: 120 }}
											/>
										</>
									)}

									{filter.comparison !== 'between' && (
										<>
											<DatePicker
												allowClear
												value={filter.value ? moment(filter.value) : undefined}
												onChange={(value) => props.updateFilter({ value: value?.toDate() })}
												style={{ minWidth: 120 }}
											/>
										</>
									)}
								</>
							)}
						</>
					)}
				</div>
			</div>
		</>
	);
}

function comparisonGroupChecker(compare: any, trait: string) {
	// for each in comparisonOptions
	if (compare == null) return null;
	// @ts-ignore
	const val: any = acctAllComparisonTypesToOptions[trait];
	if (typeof val === 'string') return val;
	return (val && val.type) || 'text';
}

function getPropertyByPath(obj: any, path: string): any {
	const keys: string[] = path.split('.');
	let value: any = obj;
	for (const key of keys) {
		value = value?.[key];
		if (value === undefined) {
			return undefined;
		}
	}
	return value;
}
