import './styles/gbutton.css';

import React, { CSSProperties } from 'react';

import utils from '../../helpers/utils';
import GPop, { GlobalPopProps } from './GPop';
import { GlobalStyleType } from './GTag';
import GTooltip, { GlobalTooltipProps } from './GTooltip';


// Primary - Green, secondary - blue
export interface GlobalButtonProps {
    children?: React.ReactNode // Can use either or
    text?: React.ReactNode // Can use either or

    icon?: React.ReactNode
    iconPadding?: string | boolean // padding to apply to icon (fa icons sometimes have weird sizes)

    color?: string // Text color
    hoverColor?: string
    background?: string // Background color
    borderColor?: string // Border (uses background by default)
    hoverable?: boolean // should this be hoverable (true by default)
    selected?: boolean // use hover styles (as if selected)
    style?: CSSProperties

    size?: 'small' | 'default' | 'large' // determines default padding & fontsize

    type?: GlobalStyleType

    optional?: boolean // optionally don't render this button
    disabled?: boolean
    loading?: boolean

    tooltip?: React.ReactNode | GlobalTooltipProps
    popover?: GlobalPopProps

    onClick?: (e: any) => void


    linkTo?: string
    noBlank?: boolean // should this open in a new tab?

    ttt?: boolean | GlobalStyleType // * Should the tooltip use the same type as the button?

    cName?: string // classname of thw wrapper div
    center?: boolean // center the button contents

}

// New button style to be used across the dash as we roll out new global styles
export default function GButton({ ...props }: GlobalButtonProps) {

    if (props.optional === false) return <></>


    const style: CSSProperties = {

    }

    if (props.center) {
        style.display = 'flex'
        style.justifyContent = 'center'
        style.alignItems = 'center'
        if (props.size === 'small' && props.center) style.width = 24
        if (props.size === 'small') style.height = 24
        if (props.size === 'default') style.height = 32
        if (props.size === 'large') style.height = 40
    }

    function createRipple(event: any) {
        const button = event.currentTarget;

        const circle = document.createElement("span");
        const diameter = Math.max(button.clientWidth, button.clientHeight);
        const radius = diameter / 2;

        circle.style.width = circle.style.height = `${diameter}px`;
        circle.style.left = `${event.clientX - button.offsetLeft - radius}px`;
        circle.style.top = `${event.clientY - button.offsetTop - radius}px`;
        circle.classList.add("ripple");

        const ripple = button.getElementsByClassName("ripple")[0];

        if (ripple) {
            ripple.remove();
        }

        button.appendChild(circle);
    }

    let icon = props.icon
    let buttonClass = `gbutton-${props.type || 'default'} gbutton-size-${props.size || 'default'} gbutton ${props.disabled ? 'disabled' : ''} ${props.loading ? 'loading' : ''} ${utils.darkMode ? 'dark' : ''}`

    if ((props.iconPadding)) {
        if (icon && (props.text || props.children)) {
            icon = <>
                <div style={{ padding: (typeof props.iconPadding === 'string' ? props.iconPadding : '0px 0px 1px 2px') }}>
                    {icon}
                </div>
            </>
        } else {
            style.padding = props.size === 'small' ? '2px 6px 3px 8px' : props.size == 'large' ? '2px 6px 3px 8px' : '2px 6px 3px 8px'
        }
    }

    if (!icon && props.loading) {
        // icon = <i className="fas fa-circle-notch fa-spin" style={{ fontSize: props.size === 'small' ? '0.625rem' : '0.75rem' }} />
        icon = <i className="fa-duotone fa-spinner-third fa-spin" style={{ fontSize: props.size === 'small' ? '0.625rem' : '0.75rem', marginRight: 1 }} />
    }

    const tooltipProps: GlobalTooltipProps = {
        tooltip: props.tooltip,
        type: props.ttt === true ? props.type : props.ttt || 'default',
        ...(typeof props.tooltip === 'object' ? props.tooltip : {})
    }

    const WrappedButton = ({ children }: any) => {
        if (!props.tooltip && props.popover) {
            return <>
                <GPop
                    {...{ type: props.ttt === true ? props.type : props.ttt || 'default', }}
                    disabled={!!props.tooltip || !props.popover}
                    {...props.popover}
                >
                    {children}
                </GPop>
            </>
        }

        return <>
            <GTooltip {...tooltipProps}>{children}</GTooltip>
        </>
    }

    const WrapLink = ({ children }: any) => {
        return props.linkTo && !props.disabled ? <a style={{ all: 'unset', }} href={props.linkTo} target={props.noBlank ? '' : '_blank'} rel='noreferrer' >{children}</a> : children
    }

    return (<div className={props?.cName}>
        <WrapLink>
            <div className={`gbutton ${utils.darkMode ? 'gbutton-dark' : ''}`}>
                <WrappedButton>
                    <button
                        className={buttonClass}
                        style={{ ...style, ...props.style }}
                        onClick={(ev) => {
                            if (props.disabled || props.loading) return null
                            createRipple(ev)
                            if (props.onClick) props.onClick(ev)
                        }}
                    >
                        {icon}{' '}{props.children || props.text}
                    </button>
                </WrappedButton>
            </div>
        </WrapLink>
    </div>)
}