import './styles/gpop.css';

import { Popover, PopoverProps, Space } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';
import React, { CSSProperties, useState } from 'react';

import { colors } from '../../helpers/allStyles';
import utils from '../../helpers/utils';
import GButton, { GlobalButtonProps } from './GButton';
import { GlobalStyleType } from './GTag';
import GTooltip, { GlobalTooltipProps } from './GTooltip';


export interface GlobalPopProps {

    debug?: boolean
    reconfirm?: boolean
    disabled?: boolean

    open?: boolean
    setOpen?: (x: boolean) => void

    children?: React.ReactNode // Can use either or

    style?: CSSProperties

    size?: "large" | "default" | "small"

    content?: React.ReactNode
    type?: GlobalStyleType

    borderTop?: string
    borderLeft?: string

    dark?: boolean
    filled?: boolean
    loading?: boolean
    popConfirm?: boolean

    overlayInnerStyle?: CSSProperties

    triggers?: 'hover' | 'click' | 'focus' | any

    mouseLeaveDelay?: number
    placement?: TooltipPlacement

    useAsync?: boolean // * wait for a resp from the onConfirm func before closing!

    tooltip?: GlobalTooltipProps // only works with popconfirm

    padded?: boolean
    padding?: string | number

    closeOnClick?: boolean

    config?: {
        flip?: boolean
        align?: 'center' | 'right' | 'left'
        size?: 'small' | 'default' | 'large'
        confirmText?: React.ReactNode
        confirmType?: GlobalStyleType
        confirmStyle?: CSSProperties
        confirmProps?: GlobalButtonProps

        cancelText?: React.ReactNode
        cancelType?: GlobalStyleType
        cancelStyle?: CSSProperties
        cancelProps?: GlobalButtonProps

        iconDisplayType?: 'duotone' | 'thin' | 'light' | 'regular' | 'solid'
        iconType?: 'info' | 'success' | 'error' | 'warning' | 'question'
        iconStyle?: CSSProperties
        iconProps?: GlobalButtonProps
    }

    icon?: React.ReactNode | boolean
    width?: number

    zIndex?: number

    onConfirm?: () => (void | Promise<void>) | (boolean | Promise<boolean>)
    onCancel?: () => (void | Promise<void>) | (boolean | Promise<boolean>)

    rawProps?: PopoverProps

}

export default function GPop({ ...props }: GlobalPopProps) {

    const [loading, setLoading] = useState<true | false | undefined>(undefined);
    const [open, uOpen] = useState<true | false>(false);
    const setOpen = (x: boolean) => {
        if (props.setOpen) props.setOpen(x)
        uOpen(x)
    }

    let style: CSSProperties = {


    }

    let popClass = `gpop gpop-${props.type || 'default'} gpop-size-${props.size || 'default'} gpop ${utils.darkMode ? 'dark' : ''} ${props.filled ? 'filled' : ''} ${props.dark ? 'dark' : ''}`

    if (props.disabled) return <>
        {props.children}
    </>

    const cConfig = {
        ...props.config,
        align: props.config?.align || 'center',
        size: props.config?.size || props.size || 'default',
        confirmText: props.config?.confirmText || 'Confirm',
        confirmType: props.config?.confirmType || 'primary',
        confirmStyle: { ...props.config?.confirmStyle },
        confirmProps: { ...props.config?.confirmProps },
        cancelText: props.config?.cancelText || 'Cancel',
        cancelType: props.config?.cancelType || 'default',
        cancelStyle: { ...props.config?.cancelStyle },
        cancelProps: { ...props.config?.cancelProps },
        iconDisplayType: props.config?.iconDisplayType || 'duotone',
        iconType: (props.config?.iconType || (props.popConfirm ? 'warning' : 'info')) as 'info' | 'success' | 'error' | 'warning' | 'question',
        iconStyle: { ...props.config?.iconStyle },
        iconProps: { ...props.config?.iconProps },
    }

    // When reconfirm is enabled, we need to make sure the confirm button is a popconfirm
    // Also if no onConfirm is provided in confirmProps, we use the props.onConfirm
    if (props.reconfirm) {
        cConfig.confirmProps = { ...cConfig.confirmProps, popover: { ...cConfig.confirmProps.popover } };
        const oldConfirm = (cConfig.confirmProps.popover?.onConfirm || props.onConfirm) as (() => Promise<boolean> | boolean)
        if (cConfig.confirmProps.popover) {
            cConfig.confirmProps.popover.popConfirm = true;
            cConfig.confirmProps.popover.content = cConfig.confirmProps.popover.content || 'Are you sure?'
            cConfig.confirmProps.popover.onConfirm = async () => {
                if (props.useAsync) setLoading(true)
                if (oldConfirm) await setLoading((await oldConfirm()) || false)
                setOpen(false)
            }
        }
    }

    const buttons = [<GButton
        key='0'
        size={cConfig.size}
        type={cConfig.cancelType}
        onClick={async () => {
            if (props.reconfirm && props.config?.cancelProps?.popover?.popConfirm) return
            if (props.useAsync) setLoading(true)
            const result: boolean = (props.useAsync ? await props.onCancel?.() : props.onCancel?.()) as boolean || false
            if (props.useAsync) setLoading(result)
            setOpen(false)
        }}
        style={cConfig.cancelStyle}
        disabled={props.loading || loading}
        {...cConfig.cancelProps}
    >
        {cConfig.cancelText}
    </GButton>,
    <GButton
        key='1'
        size={cConfig.size}
        type={cConfig.confirmType}
        onClick={async () => {
            if (props.reconfirm) return
            if (props.useAsync) setLoading(true);
            const result: boolean = (props.useAsync ? await props.onConfirm?.() : props.onConfirm?.()) as boolean || false
            if (props.useAsync) setLoading(result)
            if (!props.reconfirm) setOpen(false)
        }}
        style={cConfig.confirmStyle}
        loading={props.loading || loading}
        {...cConfig.confirmProps}
    >
        {cConfig.confirmText}
    </GButton>]

    const confirmButtons = <div style={{ width: '100%', padding: (props.icon || props.content ? '10px 0px 5px 0px' : 0), textAlign: cConfig.align }}>
        <div style={{}}>
            <Space>
                {(cConfig.flip ? buttons.reverse() : buttons)}
            </Space>
        </div>
    </div>


    const icons = {
        info: <i className={`fa-${cConfig.iconDisplayType} fa-circle-info`} style={{ color: colors.primary.blue, fontSize: '.825rem', marginRight: 2, ...cConfig.iconStyle }} {...cConfig.iconProps} />,
        warning: <i className={`fa-${cConfig.iconDisplayType} fa-circle-exclamation`} style={{ color: colors.primary.pinkRed, fontSize: '.825rem', marginRight: 2, ...cConfig.iconStyle }} {...cConfig.iconProps} />,
        question: <i className={`fa-${cConfig.iconDisplayType} fa-circle-question`} style={{ color: colors.primary.blue, fontSize: '.825rem', marginRight: 2, ...cConfig.iconStyle }} {...cConfig.iconProps} />,
        success: <i className={`fa-${cConfig.iconDisplayType} fa-circle-check`} style={{ color: colors.primary.primary, fontSize: '.825rem', marginRight: 2, ...cConfig.iconStyle }} {...cConfig.iconProps} />,
        error: <i className={`fa-${cConfig.iconDisplayType} fa-circle-xmark`} style={{ color: colors.primary.pinkRed, fontSize: '.825rem', marginRight: 2, ...cConfig.iconStyle }} {...cConfig.iconProps} />
    }

    if (props.icon === true) props.icon = icons[cConfig.iconType]

    if (!!props.tooltip?.tooltip && !open) {
        return <GTooltip {...props.tooltip} >
            <div>
                {React.cloneElement(props.children as any, { onClick: () => setOpen(!open) })}
            </div>
        </GTooltip>
    }

    const borderStyle: CSSProperties = props.borderLeft ? { borderLeft: props.borderLeft.includes('px') ? props.borderLeft : `3px solid ${props.borderLeft}` } : {}
    if (props.borderTop) borderStyle.borderTop = props.borderTop.includes('px') ? props.borderTop : `3px solid ${props.borderTop}`

    if (props.closeOnClick) {
        props.children = <div onClick={() => setOpen(!open)}>
            {props.children}
        </div>
    }

    return (<>
        <Popover
            zIndex={props.zIndex}
            {...(loading !== undefined ? { open: loading } : {})}
            trigger={props.triggers || (props.popConfirm ? ['click'] : ['hover'])}
            open={props.open || open}
            onOpenChange={(v: boolean) => {
                if (loading && props.useAsync) return
                setLoading(undefined)
                setOpen(v)
            }}
            content={<>
                <div style={{ ...(props.padding && { padding: props.padding }), ...(props.padded ? { padding: '12px 12px 0px 12px' } : {}) }}>
                    {props.icon} {props.content}
                </div>
                <div style={{ ...(props.padding && { padding: props.padding }), ...(props.padded ? { padding: 4 } : {}) }}>
                    {props.popConfirm && <>
                        {confirmButtons}
                    </>}
                </div>
            </>}
            placement={props.placement}
            mouseLeaveDelay={props.mouseLeaveDelay}
            overlayClassName={popClass}
            style={{ ...style, ...props.style }}
            overlayInnerStyle={{ maxWidth: props.width || 275, ...props.overlayInnerStyle, ...borderStyle }}
            overlayStyle={{}}
            {...props.rawProps}
        >
            {props.children}
        </Popover>
    </>)
}

