import { Select, SelectProps } from 'antd';
import React, { CSSProperties } from 'react';

import utils from '../../helpers/utils';
import { PublicStore, Store } from '@/legacy-types'
import HighlightText from '../HighlightText';

type Option = {
	label: React.ReactNode;
	value: string | number;
	options?: Option[];
};
export type StoreSelectProps = {
	stores: PublicStore[] | Store[] | any;
	className?: string;
	global?: boolean;
	placeholder?: string;
	style?: CSSProperties;
	loading?: boolean;
	ref?: React.Ref<any>;
} & Partial<SelectProps>;

const StoreSelect = ({ value, stores, defaultValue, placeholder = 'Select Store', ...props }: StoreSelectProps) => {
	const [searchValue, setSearchValue] = React.useState('');

	const options = React.useMemo(() => {
		const byLocation: { [key: string]: Option } = {};
		for (const store of stores as Store[]) {
			if (store.id === undefined || store.archived) continue;
			const { country, state, city } = store.addr || {};
			const locationKey = `${state || 'N/A'} (${country || 'N/A'})`;
			if (!byLocation[locationKey])
				byLocation[locationKey] = {
					value: locationKey,
					label: locationKey,
					options: [],
				};
			byLocation[locationKey]?.options?.push({
				value: store.id || 0,
				label: (
					<HighlightText
						text={`${store.name} (${city || 'N/A'})`}
						search={searchValue}
					/>
				),
			});
		}

		return [...(props.global ? [{ value: -1, label: 'All Stores' }] : []), ...Object.values(byLocation)];
	}, [searchValue, stores]);

	if (!stores || !stores.length) return null;
	if (defaultValue && !Array.isArray(value) && typeof defaultValue !== 'number') defaultValue = parseInt(defaultValue) || undefined;
	if (value && !Array.isArray(value) && typeof value !== 'number') value = parseInt(value) || undefined;
	if (Array.isArray(value)) value = (value || []).map((v) => (v && typeof v !== 'number' ? parseInt(v) : v));

	return (
		<Select
			ref={props.ref}
			className={`${props.className || 'storeSelect'} `}
			showSearch
			onSearch={setSearchValue}
			defaultValue={defaultValue}
			onChange={props.onChange}
			placeholder={placeholder}
			listHeight={350}
			dropdownMatchSelectWidth={450}
			filterOption={utils.filterOption}
			{...{ ...props, value }}
			style={{ maxWidth: '350px', width: '100%', ...props.style }}
			options={options}
		/>
	);
};

export default StoreSelect;
