import React, { memo } from 'react'
import GTooltip, { GlobalTooltipProps } from './GTooltip'

export type FAType = 'solid' | 'regular' | 'light' | 'duotone' | 'brands' | 'sharp'

export interface FAProps {
    classes?: (string[] | string)
    spin?: boolean
    name: string
    type?: FAType | FAType[]
    style?: React.CSSProperties

    color?: string
    fontSize?: string | number

    shift?: {
        up?: number
        down?: number
        left?: number
        right?: number
    }

    openBlank?: boolean
    linkedTo?: string
    linked?: boolean
    error?: boolean
    linkedHover?: boolean
    deleteHover?: boolean

    disabled?: boolean
    onClick?: (e: any) => void

    tooltip?: string | GlobalTooltipProps

}

export default function FA(props: FAProps) {

    const types = (Array.isArray(props.type) ? props.type : [props.type || 'regular'])
    const classes = types.map(type => `fa-${type}`)

    if (props.linked) classes.push('linked')
    if (props.linkedHover) classes.push('linked-hover')
    if (props.deleteHover) classes.push('error-hover')
    if (props.error) classes.push('error')
    if (props.spin) classes.push('fa-spin')
    if (props.name) classes.push(`fa-${props.name}`)

    if (props.classes) {
        if (Array.isArray(props.classes)) {
            classes.push(...props.classes)
        } else {
            classes.push(props.classes)
        }
    }

    const onClick = (e: any) => {
        if (props.disabled) return
        if (props.onClick) props.onClick(e)
        if (props.linkedTo) window.open(props.linkedTo, props.openBlank ? '_blank' : '_self')
    }

    const style = {
        cursor: (props.onClick || props.linkedHover || props.linkedTo) ? 'pointer' : 'inherit',
        fontSize: props.fontSize,
        color: props.color,
        ...props.style
    }

    if (props.shift) {
        const { up, down, left, right } = props.shift
        if (up) style.marginTop = up
        if (down) style.marginBottom = down
        if (left) style.marginLeft = left
        if (right) style.marginRight = right
    }

    const Icon = memo(() => <i
        onMouseDown={(e) => {
            if (e.button === 1 && props.linkedTo) {
                // window.open(props.linkedTo, '_blank')
                // Open it but dont actually switch to it
                window.open(props.linkedTo, '_blank', 'noopener,noreferrer')
            }
        }}
        onClick={onClick}
        className={classes.join(' ')}
        style={style}
    />)

    const tooltipProps = typeof props.tooltip === 'object' ? props.tooltip : { tooltip: props.tooltip }

    if (props.tooltip) {
        return <GTooltip {...tooltipProps} >
            <>
                <Icon />
            </>
        </GTooltip>
    }

    return (<>
        <Icon />
    </>)
}
