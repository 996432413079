import { colorUtils } from '../../../../helpers';
import { GInputBorderColors, GInputStatus, GInputType } from '@/legacy-types';

export const GInputLoadingGradient = `linear-gradient(90deg, 
        rgba(0,0,0,0.025) 10%, 
        rgba(0,0,0,0.025) 20%, 
        rgba(0,0,0,0.025) 30%, 
        rgba(224,224,224,0.45) 40%, 
        rgba(224,224,224,0.45) 50%, 
        rgba(0,0,0,0.025) 60%, 
        rgba(0,0,0,0.025) 70%, 
        rgba(0,0,0,0.025) 80%,
        rgba(0,0,0,0.025) 90%
    )`;

export type ApplyInputStyleProps = {
	hovered: boolean;
	focused: boolean;

	style: React.CSSProperties;
	borders: GInputBorderColors;
	config: any;
	status: GInputStatus;
};

export function applyInputStyles({ style, hovered, focused, config, borders, status }: ApplyInputStyleProps): React.CSSProperties {
	let color = borders.default;
	if (status.error) color = borders.error || color;
	if (hovered) color = borders.hover || color;
	if (focused) color = borders.focus || color;
	if (status.loading) color = borders.loading || color;
	if (status.disabled) color = borders.disabled || color;

	let hasShadow = false;
	if (focused) hasShadow = true;

	style.cursor = 'pointer';
	if (status.disabled) style.cursor = 'not-allowed';
	if (status.loading) style.cursor = 'wait';

	let background = 'transparent';
	if (status.disabled) background = `rgba(0,0,0,0.025)` || background;
	if (status.loading) background = GInputLoadingGradient || background;

	if (color && color !== 'transparent') style.border = `1px solid ${color}`;
	if (hasShadow) style.boxShadow = `0 0 0 2px ${colorUtils.setHexAlpha(color, 0.2)}`;

	if (status.loading) {
		style.backgroundSize = '200% 100%';
		style.animation = 'skeletonAnimation 5s linear infinite';
	}

	return style;
}

export function mergeInputStyle(defaultStyle: React.CSSProperties, userStyle?: React.CSSProperties): React.CSSProperties {
	return { ...defaultStyle, ...userStyle };
}

export function deepMergeInputStyles<T>(defaultProps: T, userProps?: Partial<T>): T {
	if (!userProps) return defaultProps;

	const mergedProps = { ...defaultProps };

	for (const key in userProps) {
		if (Object.prototype.hasOwnProperty.call(userProps, key)) {
			if (typeof defaultProps[key] === 'object' && defaultProps[key] !== null && !Array.isArray(defaultProps[key]) && userProps[key]) {
				mergedProps[key] = deepMergeInputStyles(defaultProps[key], userProps[key] as any);
			} else {
				mergedProps[key] = userProps[key] as any;
			}
		}
	}

	return mergedProps;
}
