import { Tooltip } from 'antd'
import { TooltipPlacement } from 'antd/es/tooltip'
import React, { CSSProperties, useState } from 'react'
import utils from '../../helpers/utils'
import { GlobalStyleType } from './GTag'


import './styles/gtooltip.css'

export interface GlobalTooltipProps {
    children?: React.ReactNode // Can use either or
    text?: React.ReactNode // Can use either or

    style?: CSSProperties

    size?: "large" | "default" | "small"

    tooltip?: React.ReactNode
    type?: GlobalStyleType

    placement?: TooltipPlacement

    filled?: boolean

    onOpenChange?: (v: boolean) => void

    dangerous?: boolean
    zIndex?: number

    enterDelay?: number
    leaveDelay?: number
}

export default function GTooltip({ ...props }: GlobalTooltipProps) {

    let style: CSSProperties = {


    }


    let tagClass = `gtooltip gtooltip-${props.type || 'default'} gtooltip-size-${props.size || 'default'} gtooltip ${utils.darkMode ? 'dark' : ''} ${props.filled ? 'filled' : ''}`


    return (<>
        <Tooltip
            zIndex={props.zIndex}
            overlayClassName={tagClass}
            title={props.dangerous ? <span dangerouslySetInnerHTML={{ __html: props.tooltip as any }} /> : props.tooltip || ''}
            placement={props.placement}
            style={{ ...style, ...props.style }}
            onOpenChange={(open) => props.onOpenChange ? props.onOpenChange(open) : undefined}
            mouseEnterDelay={props.enterDelay}
            mouseLeaveDelay={props.leaveDelay}
        >
            {props.children || props.text}
        </Tooltip >
    </>)
}

