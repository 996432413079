import { Row, Popover } from 'antd';
import merge from 'lodash/merge';
import PropTypes from 'prop-types';
import React from 'react';
import { ColorWrap, EditableInput } from 'react-color/lib/components/common';
import * as color from 'react-color/lib/helpers/color';
import { ChromePicker } from 'react-color';
import reactCSS from 'reactcss';

import utils, { binder } from '../../helpers/utils';

export const HexPicker = ({
	onChange, hex, width,
	styles: passedStyles = {}, ...props
}) => {
	let hashColor = '#fff', borderColor = utils.darkMode ? '#434343' : '#F0F0F0', badColor = false;
	if (['#ffffff'].includes(utils.strify(hex)) || hex === 'transparent') {
		hashColor = '#333';
		badColor = true;
	}
	const gBorder = (a) => `1px solid ${a ? ((badColor ? undefined : hex) || borderColor) : borderColor}`;
	const styles = reactCSS(merge({
		'default': {
			card: {
				width,
				background: '#fff',
				border: '0 solid rgba(0,0,0,0.25)',
				boxShadow: '0 1px 4px rgba(0,0,0,0.25)',
				borderRadius: '4px',
				position: 'relative'
			},
			body: { padding: '0 10px 10px 0', height: 38 },
			hash: {
				background: hex,
				height: 38,
				maxWidth: '30px',
				float: 'left',
				color: hashColor,
				borderRadius: '6px 0 0 6px',
				borderRight: badColor ? gBorder(true) : 'none',
				borderTop: gBorder(true),
				borderLeft: gBorder(true),
				borderBottom: gBorder(true),
				// boxShadow: 'inset 0 0 0 1px #F0F0F0',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				padding: '5px 15px',
				fontSize: '.875rem',
				cursor: 'pointer'
			},
			input: {
				background: utils.darkMode ? 'transparent' : '',
				maxWidth: '60px',
				fontSize: '.875rem',
				color: '#666',
				borderLeft: 'none',
				borderTop: gBorder(),
				borderRight: gBorder(),
				borderBottom: gBorder(),
				outline: 'none',
				minHeight: 36,
				maxHeight: 36,
				// boxShadow: 'inset 0 0 0 1px #F0F0F0',
				boxSizing: 'content-box',
				borderRadius: '0 6px 6px 0',
				padding: '0 7px 0 15px'
			},
			clear: { clear: 'both' },
			triangle: {
				width: '0px',
				height: '0px',
				borderStyle: 'solid',
				borderWidth: '0 9px 10px 9px',
				borderColor: 'transparent transparent #fff transparent',
				position: 'absolute'
			},
			triangleShadow: {
				width: '0px',
				height: '0px',
				borderStyle: 'solid',
				borderWidth: '0 9px 10px 9px',
				borderColor: 'transparent transparent rgba(0,0,0,.5) transparent',
				position: 'absolute'
			},
		}
	}, passedStyles));

	const bndClr = {
			getData: () => hex,
			setData: (c) => onChange({ hex: c.color })
		},
		cbnd = (k, def) => binder(k, bndClr, { color: true, value: def });

	const handleChange = (hexcode, e) => {
		color.isValidHex(hexcode) && onChange({
			hex: hexcode,
			source: 'hex'
		}, e);
	};

	let h = utils.strify(hex), d = utils.strify(props?.def || '');
	// ! eventually dont be lazy
	if (h === '#fff') h = '#ffffff';
	if (d === '#fff') d = '#ffffff';
	if (h === '#000') h = '#000000';
	if (d === '#000') d = '#000000';
	const isNotDefault = props?.def && props?.def !== 'custom' && (h !== d);

	return (<div>
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<Row wrap={false} className="hexPicker" style={styles.body}>
				<Popover className='oof' trigger="click" placement="topLeft" content={<ChromePicker {...cbnd('color', hex)} disableAlpha={true} />} style={{ }}>
					<div style={styles.hash}>
						<i className="fa-duotone fa-eye-dropper" style={{ opacity: 0.8, fontSize: '0.825rem' }} />
					</div>
				</Popover>
				<EditableInput
					style={{ input: styles.input }}
					value={hex.replace('#', '')}
					onChange={handleChange}
				/>
				<div style={styles.clear} />
			</Row>
			{(props?.reset && isNotDefault) && <>
				<i className="fa-regular fa-arrow-rotate-left linked" style={{}} onClick={() => handleChange(props?.def, null)} />
			</>}
		</div>
	</div>);
};

HexPicker.propTypes = {
	width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
	styles: PropTypes.object
};

HexPicker.defaultProps = {
	width: 276,
	styles: {}
};

export default ColorWrap(HexPicker);
